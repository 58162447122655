import React from "react";
import { Container } from "reactstrap";
import Layout from "components/Layout";
import { Section } from "components/section";

const NotFoundPage = () => {
  return (
    <Layout>
      <Section>
        <Container>
          <h1>Page not found...</h1>
          <p className="text-center">
            Sorry, looks like you're looking for a page that doesn't exist
            anymore!
          </p>
        </Container>
      </Section>
    </Layout>
  );
};

export default NotFoundPage;
